import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/michelebruno/coding/music-branding-basics/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Megaphone = makeShortcode("Megaphone");
const Video = makeShortcode("Video");
const Quote = makeShortcode("Quote");
const AudioGrid = makeShortcode("AudioGrid");
const Audio = makeShortcode("Audio");
const Conclusion = makeShortcode("Conclusion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The journey towards `}<strong parentName="p">{`Music Branding`}</strong>{` has just started. `}<em parentName="p">{`The reader will also need to acquire technical knowledge`}</em>{` on the matter
of sound and its  effects to fully grasp the complexity of the world in which we are about to immerse
ourselves. In order to better understand music and how it affects human beings, an in-depth focus on the physics behind sound
and its measurable physical properties is now proposed.`}</p>
    <Megaphone id={"TTH7636978818"} mdxType="Megaphone" />
    <iframe title="Embed Player" width="100%" height="188px" src="https://embed.acast.com/5b9048d7ccdae177435e1460/5e49db5a306c318725d1dfa8" scrolling="no" frameBorder="0" style={{
      "border": "none",
      "overflow": "hidden"
    }}></iframe>
    <Video url="https://www.youtube.com/watch?v=0WtDmbr9xyY" mdxType="Video" />
    <Video url="https://www.youtube.com/watch?v=0WtDmbr9xyY" mdxType="Video" />
    <h3>{`Local videos`}</h3>
    <Video url="/2543259263.mp4" mdxType="Video" />
    <hr></hr>
    <p>{`Questa è una nota`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <img {...{
      "src": "https://images.unsplash.com/photo-1596079890744-c1a0462d0975?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3542&q=80",
      "alt": "Immagine di un orecchio"
    }}></img>
    <img {...{
      "src": "01/fig01.png",
      "alt": "Alt text",
      "title": "Didascalia Immagine"
    }}></img>
    <Quote mdxType="Quote">
  “Sound is an alternation in pressure, particle displacement, or particle velocity which is propagated in an elastic medium.[...] Sound is also the auditory sensation produced through the ear by the alterations described above.”
    </Quote>
    <Megaphone id={"TTH7636978818"} mdxType="Megaphone" />
    <AudioGrid mdxType="AudioGrid">
  <Audio url="mastercard_bogota.mp3" title="Bogotà" mdxType="Audio">
   Pippone che va sotto
  </Audio>
    </AudioGrid>
    <Quote author="Zio Frank" mdxType="Quote">
"Hearing is the first of the five senses to develop when the fetus is inside the womb."
    </Quote>
    <Conclusion mdxType="Conclusion">
Most are in agreement that music has a strange and wonderful power over our species, even though there remains a lack of consensus as to why humans have responded so positively to music throughout their history.
    </Conclusion>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Brand`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Audio logo - recognized`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Audio logo - didn’t recognize`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`industry assigned`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Most voted descriptive adjective (first)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`McDonalds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75.9%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24.1%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`food and beverages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Joyful, Complex, Simple, Smart`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Intel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39.2%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60.8%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`technology`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Technical, Fast, Natural, Slow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`XBox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10.7%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89.3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`technology`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Technical, Energetic, Rhythmic, Happy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rai`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44.3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55.7%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`movies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Likable, Simple, Smart, Reflective`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`THX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13.3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86.7%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`movies`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Energetic, Fast, Technical, Reflective`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BMW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19.6%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80.4%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`automotive`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Energetic, Natural, Fast, Reflective`}</td>
        </tr>
      </tbody>
    </table>
    <undefined>{`
      `}<div {...{
        "className": "footnotes"
      }}>{`
        `}<hr parentName="div"></hr>{`
        `}<ol parentName="div" {...{
          "style": {
            "listStyle": "none"
          }
        }}>{`
    
    `}<li parentName="ol" {...{
            "className": "footnote-list-item",
            "id": "fn-1",
            "style": {
              "display": "inline"
            }
          }}>{`
          `}<span parentName="li" {...{
              "className": "footnote-marker-text",
              "style": {
                "display": "inline"
              }
            }}>{`1.`}</span>{`
        
      `}<a parentName="li" {...{
              "href": "#fnref-1",
              "className": "footnote-backref",
              "style": {
                "display": "inline",
                "textDecoration": "none"
              }
            }}>{`
        ➚
      `}</a>{`
    `}<p parentName="li" {...{
              "className": "footnote-paragraph",
              "style": {
                "display": "inline",
                "marginLeft": "5px"
              }
            }}>{`Here's the footnote`}</p>{`
      `}</li>{`
      
    `}</ol></div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      